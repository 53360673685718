/* colors */
:root {
  --main-green:#4DA850;
}

body { 
  padding: 1rem; 
}

a {
  font-weight: bold;
}

a:hover {
  color: var(--main-green);
}

.datagrid {
  margin-top: 50px;
}

.navbar a {
  color: white;
  padding: 10px;
}

.display-8 {
  font-size: 22px !important;
  padding-top: 5px;
}

* { box-sizing: border-box; }

@media only screen and (max-width: 1200px) {
  .css-gcwpim{
    grid-template-columns: repeat(2, 1fr) !important;
  }
  .datagrid img {
    padding-bottom: 10px;
  }
  .css-10xzzei {
    width: 150px !important;
    height: 150px !important;
    right: 20px !important;
    font-size: 11px !important;
    bottom: -40px !important;
  }
}

@media only screen and (max-width: 1200px) {
  .css-gcwpim{
    grid-template-columns: repeat(1, 1fr) !important;
  }
  .display-6 {
    font-size: 28px !important;
  }
  .display-8 {
    font-size: 18px !important;
  }
  .display-10 {
    font-size: 16px !important;
  }
}

@media only screen and (max-width: 500px) {
  .css-gcwpim{
    grid-template-columns: repeat(1, 1fr) !important;
  }
  .display-6 {
    font-size: 22px !important;
  }
  .display-8 {
    font-size: 14px !important;
  }
  .display-10 {
    font-size: 12px !important;
  }
}

/* filters */
.filter {
  background: white;
  text-decoration: none;
  padding-bottom: 10px;
  font-weight: 500;
}
.filter img {
  opacity: 0.5;
}
.filter:hover{
  border-bottom: 5px solid var(--main-green);
  transition: all ease-in-out .2s;
}

.selected {
  border-bottom: 5px solid var(--main-green);
  color: var(--main-green);
}

.selected img {
  opacity: 1.0;
}

.unselected {
  border-bottom: none;
  color: black;
}

.subscribe-box {
  font-size: 1rem;
}

.substack-frame {
  width: 100%;
}

/* footer */

.footer {
  text-align: center;
  padding: 50px;
}

.footer a:hover {
  color: var(--main-green)
}

/* banner */
.background {
  background-image: url("background.png");
  background-size: cover;
  background-color: var(--main-green); 
  z-index:1
}

/* sticky note */
.css-10xzzei a:hover {
  color: var(--main-green)
}


/* loading animation */ 
.loader{
  max-width: 400px;
  margin: auto;
  font-size: 2rem;
  width: 100%;
  height: 70vh;
  text-align: center;
}

.lds-heart {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  transform: rotate(45deg);
  transform-origin: 40px 40px;
}
.lds-heart div {
  top: 32px;
  left: 32px;
  position: absolute;
  width: 32px;
  height: 32px;
  background: var(--main-green);
  animation: lds-heart 1.2s infinite cubic-bezier(0.215, 0.61, 0.355, 1);
}
.lds-heart div:after,
.lds-heart div:before {
  content: " ";
  position: absolute;
  display: block;
  width: 32px;
  height: 32px;
  background: var(--main-green);
}
.lds-heart div:before {
  left: -24px;
  border-radius: 50% 0 0 50%;
}
.lds-heart div:after {
  top: -24px;
  border-radius: 50% 50% 0 0;
}
@keyframes lds-heart {
  0% {
    transform: scale(0.95);
  }
  5% {
    transform: scale(1.1);
  }
  39% {
    transform: scale(0.85);
  }
  45% {
    transform: scale(1);
  }
  60% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(0.9);
  }
}

/* fade in */
.fade-in {
  animation: fadein 2s;
  -moz-animation: fadein 2s; /* Firefox */
  -webkit-animation: fadein 2s; /* Safari and Chrome */
  -o-animation: fadein 2s; /* Opera */
}
@keyframes fadein {
  from {
      opacity:0;
  }
  to {
      opacity:1;
  }
}
@-moz-keyframes fadein { /* Firefox */
  from {
      opacity:0;
  }
  to {
      opacity:1;
  }
}
@-webkit-keyframes fadein { /* Safari and Chrome */
  from {
      opacity:0;
  }
  to {
      opacity:1;
  }
}
@-o-keyframes fadein { /* Opera */
  from {
      opacity:0;
  }
  to {
      opacity: 1;
  }
}

/* ---- grid ---- */
.header {
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 1;
  height: auto;
  /* padding-bottom: 10px; */
}

.filters-shadow {
  box-shadow: 0 8px 6px -6px rgb(201, 201, 201);

}

.grid-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  max-width: 60%; /* Set max-width to match max-width of .header */
  margin: 0 auto;
  height: auto;
}

.grid-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.grid-item a {
  display: block;
  width: 100%;
  height: 100%;
  padding: 10px;
  text-decoration: none;
  background-color: transparent;
  border: 2px solid transparent;
  transition: all 0.2s ease-in-out;
}

.grid-item a:hover {
  background-color: #f2f2f2;
  border: 2px solid #666666;
}

.grid-item img {
  width: 50%;
  height: auto;
}

.parent {
  position: relative;
}

.child {
  position: absolute;
  top: 0;
  left: 0;
}

@media only screen and (max-width: 600px) {
  .header {
    height: auto;
    padding-bottom: 10px;
    max-width: 100%; /* Set max-width to 100% for full width */
    padding: 10px
  }

  .grid-container {
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
    height: auto;
    margin: 0 auto;
    max-width: 100%; /* Set max-width to 100% for full width */
  }

  .grid-item a {
    padding: 5px;
  }

  .grid-item img {
    width: auto;
    height: 80px;
    max-width: 100px;
    object-fit: cover;
  }

  .grid-item p {
    font-size: 12px;
  }
}

